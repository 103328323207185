import api from "@api/Apis";
import { CheckCircle, HighlightOff } from "@material-ui/icons";
import _ from "lodash";
import Link from "next/link";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import DialogComponent from "./DialogComponent";
import cookieCutter from 'cookie-cutter'
import Cookie from "js-cookie";
import Cookies from "js-cookie";

//login check
export function isLoginCheck() {
    try {
        const token = localStorage.getItem('Token') ? localStorage.getItem('Token') : null;
        return token;
    } catch (err) {
        return false;
    }
}

//check on tesk it result Release

export function CartDefaultChecked(testKitResult, cartdata) {
    if (cartdata) {
        if (testKitResult === false) {
            const CheckAutoship = _.find(cartdata?.products, (row) => ["SN-CC-CAPS-30", "SN-CC-PWDR-30", "SN-CC-CAPS-30-NEW", "SN-CC-PWDR-30-NEW"].includes(row?.product?.sku));
            return CheckAutoship;
        } else {
            return undefined;
        }
    }

}

//checked user parent is affilate and show it's discount
export function isPercentageAssign() {
    try {
        const checkPercentage = localStorage.getItem('profileData') ? JSON.parse(localStorage.getItem('profileData')) : null;
        if (checkPercentage?.affiliate_discount_amount) {
            return checkPercentage?.affiliate_discount_amount;
        }
    } catch (e) {
        return null;
    }
}

// calculate by percentage
export function PriceCalculate(percentage, price) {
    return percentage ? price - price * (percentage / 100) : price;
}

//replace cost price on the basis of percentage
export function _getCartProducts(data) {
    let CART_DATA = [];
    const DISCOUNT_VALUE = isPercentageAssign();
    if (DISCOUNT_VALUE) {
        CART_DATA = _.map(data?.products, (row) => {
            row['product']['cost_price'] = row['discount_amount'];
            if (row?.is_autoship === "True" || +row?.product?.autoship_cost_price !== 0) {
                row['product']['autoship_cost_price'] = row['discount_amount'];
            }
            return row
        })
    }
    let PRODUCTS = data;
    PRODUCTS['products'] = CART_DATA?.length > 0 ? CART_DATA : data?.products;
    return PRODUCTS;
}

//Quantity check for 

export function _freeKitQuantityCheck(currentVal, products, productId) {
    let QUANTITY = null;
    const FREE_KIT_PRODUCT = _.find(products, (row) => (row?.id === productId));
    if (FREE_KIT_PRODUCT?.free_kit_quantity) {
        if (currentVal < +FREE_KIT_PRODUCT?.free_kit_quantity) {
            QUANTITY = currentVal + 1;
        }
    } else {
        QUANTITY = currentVal + 1;
    }
    return QUANTITY;
}

//Anchor component 

export const LinkAnchor = ({ children, className, ...props }) => (
    <Link exact="true" href={props?.href} >
        <a className={className} target={props?.target} >{children}</a>
    </Link>
);

//left right images of sites 
export const LeftRightDesginIcon = ({ children }) => (
    <>
        <div className="left_side_imageDesign">
            <img src="/images/molecularleft.png" />
        </div>
        {children}
        <div className="right_side_imageDesign">
            <img src="/images/molecular_right.png" />
        </div>
    </>
);
export const ReferralVerify = ({ subdomainUserCheckFunction, value, refferalFromUrl, setCorrectRefferal, correctRefferal }) => {
    const PARENT_REFFERAL = Cookie.get('refferal-code')?.length > 0 ? Cookie.get('refferal-code') : null;
    const [refferalCode, setRefferalCode] = useState({
        value: '',
        verify: null
    });

    useEffect(() => {
        setRefferalCode({
            value: value ? value : '',
            verify: value ? true : false
        })
    }, [value])

    return (
        <div className="d-flex">
            <label>Who referred you?</label>
            <div className="wrap-field">
                <input className="form-control" type="text" name="refferal" defaultValue={PARENT_REFFERAL} onChange={(e) => {
                    setRefferalCode({
                        value: e.target.value,
                        verify: false,
                    });
                    setCorrectRefferal("")
                }} />
                <button type="button" disabled={(!refferalCode?.value && !refferalFromUrl)} onClick={() => subdomainUserCheckFunction(refferalCode?.value)}>
                    {refferalCode?.verify === true && (<CheckCircle style={{ color: "green" }} />)}
                    {refferalCode?.verify === false && (<HighlightOff style={{ color: "red" }} />)}
                    <span>{refferalCode?.verify === true || correctRefferal?.status ? "Verified" : "Verify"}</span>
                </button>
            </div>
        </div>
    )

}

//getAllProductsApi
export const _getAllProduct = async (onlyAutoShip = false, category) => {
    const PARENT_REFERRAL = Cookie.get('refferal-code')?.length > 0 ? Cookie.get('refferal-code') : null;
    try {
        const PRODUCT_DATA = await api.getAllProduct({ category, refferalCode: PARENT_REFERRAL, slug: 'us' });
        const { status, data } = PRODUCT_DATA;
        if (status === 200) {
            if (onlyAutoShip) {
                return _.filter(data?.products, (row) => row?.is_custom_product === "True");
            }
            return data?.products || [];
        }
    } catch (error) {
        console.error('Error fetching products:', error);
        throw new Error('Something went wrong with the products API');
    }
};


//NewYork state pop-Up
export const NewYorkPopUp = ({ open, setClose }) => (
    <DialogComponent opend={open} handleClose={setClose} title="Note" classFor="new_york_popup">
        <p>SNiP Nutrigenomics is able to ship you a DNA test kit but it is against New York State law to ship DNA samples out of state.</p>
        <p> Please <LinkAnchor href="mailto:support@snipnutrition.com"> contact us </LinkAnchor>
            for more information.</p>
    </DialogComponent>
);

//deleteFromCart
export const deleteCartProduct = async (productId, variantId, cartId, is_autoship) => {
    let successMsg = null;
    const formDataDelete = { product_id: productId, variant_id: variantId, id: cartId, is_autoship: is_autoship };
    await api.deleteProductFromAddToCart(formDataDelete).then(res => {
        if (res?.data?.code === 1) {
            successMsg = res?.data?.message;
        }
    })
    return { success: successMsg }
}


//Add to cart

export const addToCartMethod = async (data, setshowminicart, setCartNotify, cartProducts, setCartData, freeKitRemove = false, setErrorMsg) => {

    const { id, quantity, is_autoship } = data;
    const PRODUCT_ON_CART = (cartProducts && cartProducts?.length > 0) ? cartProducts : [];
    const FILTER_PRODUCTS = _.find(PRODUCT_ON_CART, { is_autoship: "True" });

    // if (FILTER_PRODUCTS && is_autoship) {
    //     deleteCartProduct(FILTER_PRODUCTS?.product?.id, null, FILTER_PRODUCTS?.id, true, setCartData);
    // }
    if (freeKitRemove) {
        const FREE_KIT = _.find(PRODUCT_ON_CART, (row) => row?.product?.sku === "SN-DNA-KIT-BUNDLE");
        deleteCartProduct(+FREE_KIT?.product?.id, null, FREE_KIT?.id, false, setCartData);
    }

    const LOGIN_TOKEN = localStorage.getItem('Token') ? localStorage.getItem('Token') : null;
    const formData = {
        product_id: id,
        variant_id: null,
        token: LOGIN_TOKEN,
        quantity: quantity,
        is_autoship: is_autoship,
    };

    await api.addToCart(formData).then(res => {
        if (res?.data?.code === 1) {
            setCartNotify("Added To Cart")
            setTimeout(() => { setCartNotify(''); }, 3000)
            setshowminicart(true);
            setErrorMsg('');
        }
        if (res?.data?.code === 0) {
            setCartNotify(res?.data?.message);
            setTimeout(() => { setCartNotify(''); }, 3000)
            setshowminicart(false);
            setErrorMsg(res?.data?.message);
        }
    })
}


//check Refferal and then set path according that
export function refferalRouting(url) {
    // const router = useRouter();

    // if (router.asPath?.includes('#')) {
    //     const SPLIT_URL = _.split(router.asPath, "#");
    //     if (SPLIT_URL?.length > 0) {
    //         return url + `/#${SPLIT_URL[1]}`;
    //     }
    // } else {
    return url;
    // }
}

//updateAutoship product 
export const UpdateSmartShip = (props) => {
    const [errorProductQty, setErrorProductQty] = useState("");

    const { product_id, cartId, quantity, defualtAutoship, token, setCartData, callbackFunction, setcoupon, setdiscount_amount, setAmountCalculations } = props;
    async function _UpdateApi(update_data) {
        if ((update_data?.datas?.is_autoship && update_data?.datas?.quantity > 3)) {
            setErrorProductQty("The maximum quantity you can add is 3")
            setTimeout(() => {
                setErrorProductQty("");
            }, 3000);
        }
        else {
            await api.updateCart(update_data).then(res => {
                if (res?.status === 200) {
                    callbackFunction('switchAutoShip', token);
                    setErrorProductQty("");
                }
            });
        }
    }
    return (
        <div className="box" style={{ display: "grid" }}>
            <select value={(defualtAutoship === "True") ? 'autoship' : 'one-time'} className="form-select form-select-lg mb-3"
                onChange={(event) => _UpdateApi({
                    token,
                    datas: {
                        product_id,
                        variant_id: null,
                        quantity: +quantity,
                        id: cartId,
                        is_autoship: event.target.value === "one-time" ? false : true,
                        cookie_id: cookieCutter.get('sessionkey'),
                    }
                })}
            >
                <option value="one-time">One-Time</option>
                <option value="autoship">Subscription</option>
            </select>
            {errorProductQty && <span style={{ color: "red" }}>{errorProductQty}</span>}
        </div>

    )
}


//check user already exist or not throughout his email

export const chectAlreadyUser = async (email) => {
    const check = await api.CheckUserEmail({ email }).then((res) => {
        if (res?.status === 200) return true;
        if (res?.status === 401) return false;
    }).catch((err) => {
        throw new Error('Error from Check user Email Api from custom method')
    });
    return check;
}

//download_pdf function >>>>>>>>>>>>>>>>

export const dowload_pdf = async (url, fileText) => {
    fetch(url).then(async function (t) {
        return t.blob().then((b) => {
            var a = document.createElement("a");
            a.href = URL.createObjectURL(b);
            a.setAttribute("download", _.replace(fileText, ' ', '_'));
            a.click();
        });
    });
}
